import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { Col, Row, Dropdown, Container, Modal, Spinner, ButtonToolbar, Button, Form } from 'react-bootstrap';
import PageHeader from '../../components/layout/PageHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import AdvancedSearch from './components/AdvancedSearch';
import EmailConfig from './components/ConfigureEmail';
import TableConfiguration from './components/TableConfiguration';
import ClientBankingModal, { updatingClientBankingToken } from './clientBanking/ClientBankingModal';
import Table from '../../components/Table';
import { defaultSorted } from '../payments/RefundPayment'
import { Crumb } from "../../models/Crumb";
import { connect, useDispatch } from "react-redux";
import { CLEAR_DOCUMENT, CREATE_DOCUMENT_FAILURE, CREATE_DOCUMENT_REQUEST, CREATE_DOCUMENT_SUCCESS } from '../../redux/actions/document';
import { OrderStatus, SearchList, TransactionTypeEnum } from "../../models/Payment";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { sendErrorToastAction, sendSuccessToastAction } from "../../redux/actions/toast";
import { IPageableResults } from "../../models/PageableResults";
import { ClientBanking, ClientName, DepartmentName, PaymentChannelName, SortDirection } from "../../models/Client";
import {
    deleteClientBankingAction, getClientNamesAction, resetClientBankingDetails,
    DELETE_CLIENT_BANKING_FAILURE, DELETE_CLIENT_BANKING_REQUEST, DELETE_CLIENT_BANKING_SUCCESS,
    SAVE_CLIENT_BANKING_FAILURE, SAVE_CLIENT_BANKING_REQUEST, SAVE_CLIENT_BANKING_SUCCESS, SET_CLIENT_BANKING_DETAILS
} from "../../redux/actions/clients/clients";
import { getDepartmentNamesAction } from "../../redux/actions/clients/departments";
import { ClientNameCellFormatter, CurrencyFormatter, DepartmentNameCellFormatter, PaymentChannelNameCellFormatter } from "../../components/Formatters";
import { ReportType } from "../../models/Reports";
import { GET_TRANSACTIONS_DETAIL_REQUEST, GET_TRANSACTIONS_DETAIL_SUCCESS } from "../../redux/actions/payments/paymentTransactions";
import { Routes } from "../../routes";
import { Redirect } from 'react-router-dom';
import { User } from "../../models/User";
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { deleteActionToken } from "./clientBanking/ClientBanking";
import FormHeaderConfirmation from "../../components/FormHeaderConfirmation";
import icoWarning from "../../assets/img/icons/ico-warning-outline.svg";
import PermissionModule, { Permissions } from '../../components/usePermissions';
import { setProcessOrderAction } from "../../redux/actions/payments/orderManagement";
import { getAllPaymentChannelNamesAction } from "../../redux/actions/clients/paymentChannels";
import { formatDynamicSearchParameters } from "../../utils/advanceSearchParameters";
import { PdfExport, CsvExport } from '../payments/components/Export'
import { OrderLinesExport } from '../payments/components/customOrderLinesExport'
import _ from "lodash";
import { UPDATE_USER_TABLE_PREFERENCES_FAILURE, UPDATE_USER_TABLE_PREFERENCES_REQUEST, UPDATE_USER_TABLE_PREFERENCES_SUCCESS } from "../../redux/actions/reports/report";


interface IReportProps {
    formSchema: any;
    reportTitle: string;
    reportColumns: Map<ReportType, Array<any>>;
    rowStyle?: any;
    documentContentUrl?: string;
    documentActionResult: IActionResult;
    blobObject?: Blob;
    isFetching: boolean,
    isFetchingNames: boolean,
    currentPage: IPageableResults<any>,
    transactionType: TransactionTypeEnum,
    reportType: ReportType,
    clientNames: Array<ClientName>,
    departmentNames: Array<DepartmentName>,
    paymentChannelNames: Array<PaymentChannelName>,
    clientBanking?: ClientBanking,
    clientActionResult?: IActionResult,
    searchFields: SearchList,
    breadCrumbRoute: string,
    searchSummary?: boolean,
    currentUser: User;
    tablePreferences?: any,
    isSaving: boolean,
    actionResult: IActionResult,
    reportActionResult: IActionResult,
    preferenceUpdated?: boolean
}

export const AlignRightFormatter = (value: any, row: any) => {
    return (<span style={{ float: "right" }}>{value}</span>)
}

const Report = ({ formSchema, reportTitle, reportColumns, rowStyle, documentContentUrl, documentActionResult, blobObject, isFetching, isFetchingNames, currentPage,
    transactionType, reportType, clientNames, departmentNames, paymentChannelNames, searchFields, breadCrumbRoute, searchSummary, clientBanking,
    tablePreferences, clientActionResult, currentUser, isSaving, actionResult, reportActionResult, preferenceUpdated }: IReportProps) => {

    const dispatch = useDispatch();
    const actionToken = "Report";
    const [showEmailOccurrence, setEmailOccurrence] = useState(false);
    const [showTableConfig, setShowTableConfig] = useState(false);
    const [data, setData] = useState(Array<any>(''));
    const [searchReady, setSearchReady] = useState<boolean>(false);
    const [tableReady, setTableReady] = useState<boolean>(false);
    const [documentContentUrlWithSearchParams, setDocumentContentUrlWithSearchParams] = useState<string>();
    const [waitingForDownload, setWaitingForDownload] = useState<boolean>(false);
    const [columns, setColumns] = useState<Array<any>>([]);
    const [redirect, setRedirect] = useState<string>("");
    const [clientBankingModal, setClientBankingModal] = useState<boolean>(false);
    const [deleteClientBankingModal, setDeleteClientBankingModal] = useState<boolean>(false);
    const [sizePerPage, setSizePerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [sortBy, setSortBy] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<string>('');
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const hasFetchedData = useRef(false);

    const doToolbar = () => {
        if (!isFetching && data && data.length > 0 && data[0] != '' && reportType != ReportType.ClientBanking) {
            return (
                <div className="flexContainer">
                    <div>
                        <Button variant="outline-secondary" onClick={() => setShowTableConfig(true)}>
                            <FontAwesomeIcon icon={faCog} size="sm" />
                        </Button>
                    </div>
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle className="approvalAction" variant="outline-secondary" disabled={waitingForDownload}>
                                {waitingForDownload ?
                                    <Spinner animation="border" />
                                    :
                                    <FontAwesomeIcon icon={faArrowToBottom} size="sm" />
                                } Download Summary
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {documentContentUrlWithSearchParams ?
                                    <>
                                        <Dropdown.Item onClick={() => downloadReport('pdf')} > PDF Summary </Dropdown.Item>
                                        <Dropdown.Item onClick={() => downloadReport('csv')}> CSV Summary </Dropdown.Item>
                                        <Dropdown.Item onClick={() => downloadReport('csvDetail')}> CSV Details </Dropdown.Item>
                                        <Dropdown.Item onClick={() => downloadReport('csvOrderLines')}> CSV Items</Dropdown.Item>
                                    </>
                                    :
                                    <></>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            )
        }
        if (reportType === ReportType.ClientBanking) {
            return (
                <div className="flexContainer">
                    {!isFetching && data && data.length > 0 && data[0] != '' &&
                        <div>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-secondary">
                                    <FontAwesomeIcon icon={faCog} size="sm" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setShowTableConfig(true)}>Configure report table columns</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    }

                    <div>
                        <PermissionModule
                            permission=
                            {[
                                Permissions.ClientBankingCreate
                            ]}
                        >
                            <ButtonToolbar>
                                <Button onClick={() => { dispatch(resetClientBankingDetails(actionToken)); setClientBankingModal(true) }}>
                                    <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Record</Button>
                            </ButtonToolbar>
                        </PermissionModule>
                    </div>
                </div>
            )
        }
        else {
            return (<></>);
        }
    }

    const downloadReport = (type: string) => {
        setWaitingForDownload(true)
        type === 'pdf' ? PdfExport(data, reportType, clientNames, departmentNames, paymentChannelNames, searchFields?.createdAt).then(() => setWaitingForDownload(false))
            :
            type === 'csv' ? CsvExport(data, reportType, clientNames, departmentNames, paymentChannelNames, searchFields?.createdAt, true).then(() => setWaitingForDownload(false))
                :
                type === 'csvOrderLines' ? OrderLinesExport(data, reportType, clientNames, departmentNames, paymentChannelNames, searchFields?.createdAt).then(() => setWaitingForDownload(false))
                    :
                    CsvExport(data, reportType, clientNames, departmentNames, paymentChannelNames, searchFields?.createdAt).then(() => setWaitingForDownload(false))
    }

    const onTableChange = (type: string, { page, sortField, sortOrder, sizePerPage }: any) => {
        sortField === 'clientMsbId' ? setSortBy('clientName') : sortField === 'departmentMsbId' ? setSortBy('departmentName') : setSortBy(sortField)
        setSortDirection(sortOrder)
        setSizePerPage(sizePerPage);
        setPage(page);
    }

    const remoteDefaultSorted = [{
        dataField: currentPage?.sortBy === 'departmentName' ? 'departmentMsbId' : currentPage?.sortBy === 'clientName' ? 'clientMsbId' : currentPage?.sortBy,
        order: SortDirection[currentPage?.sortDirection]
    }];

    useEffect(() => {
        if (reportActionResult && reportActionResult.result && reportActionResult.type === UPDATE_USER_TABLE_PREFERENCES_REQUEST) {
            if (reportActionResult.result === UPDATE_USER_TABLE_PREFERENCES_SUCCESS) {
                dispatch(sendSuccessToastAction("Table columns successfully saved."));
                setShowTableConfig(false);
            } else if (reportActionResult.result === UPDATE_USER_TABLE_PREFERENCES_FAILURE) {
                dispatch(sendErrorToastAction("Table columns could not be saved."));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportActionResult]);

    useEffect(() => {
        if (reportColumns?.has(reportType)) {
            const calculatedOriginalColumns: any[] = reportColumns.get(reportType)?.map(column => ({ ...column })) || [];
            const tablePref = (Array.isArray(tablePreferences) ? tablePreferences : []).find((pref: any) => pref.pageName === reportType);

            const tableColumns = tablePref?.columns || [];
            let updatedColumns: any[];
            if (tableColumns.length > 0 && !preferenceUpdated) {
                const orderedColumns = tableColumns
                    .map((tableColumn:any) => {
                        return calculatedOriginalColumns.find(
                            (col: any) => col.dataField === tableColumn.propertyName
                        );
                    })
                    .filter((col:any) => col !== undefined);
            
                const remainingColumns = calculatedOriginalColumns.filter(originalColumn =>
                    !tableColumns.some((tableColumn:any) => tableColumn.propertyName === originalColumn.dataField)
                );
            
                updatedColumns = [...orderedColumns, ...remainingColumns].map(originalColumn => {
                    const tableColumn = tableColumns.find(
                        (col: any) => col.propertyName === originalColumn.dataField
                    );
                    return {
                        ...originalColumn,
                        default: !!tableColumn,
                    };
                });
            } else {
                updatedColumns = calculatedOriginalColumns;
            }
            
            const existingActionColumn = updatedColumns.find(col => col.text === 'Actions');
            if (!existingActionColumn) {
                const actionsColumnToAdd: any = { ...calculatedOriginalColumns[calculatedOriginalColumns.length - 1] };
                updatedColumns.push(actionsColumnToAdd);
            }
            setColumns(updatedColumns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportColumns, tablePreferences]);

    useEffect(() => {
        if (!hasFetchedData.current) {
            hasFetchedData.current = true;
            if (!clientNames) {
                dispatch(getClientNamesAction(actionToken));
            }
            if (!departmentNames) {
                dispatch(getDepartmentNamesAction(actionToken));
            }
            if (!paymentChannelNames) {
                dispatch(getAllPaymentChannelNamesAction(actionToken));
            }
        }
    }, [actionToken, clientNames, departmentNames, paymentChannelNames, dispatch]);


    const nameFormatters = (items: any[]) => {
        const clientLookup = Object.fromEntries(
            Object.values(clientNames).map(client => [client.msbId, client.businessName])
        );
        const departmentLookup = Object.fromEntries(
            Object.values(departmentNames).map(department => [department.msbId, department.name])
        );
        const paymentChannelLookup = Object.fromEntries(
            Object.values(paymentChannelNames).map(channel => [channel.msbId, channel.name])
        );
        return items.map(item => ({
            ...item,
            clientId: clientLookup[item.clientId] || item.clientId,
            departmentId: departmentLookup[item.departmentId] || item.departmentId,
            paymentChannelId: paymentChannelLookup[item.paymentChannelId] || item.paymentChannelId
        }));
    };
    
    useEffect(() => {
        if (documentContentUrl && searchFields) {
            let url = new URL(documentContentUrl, "http://temp.com");

            Object.entries(searchFields).forEach(([key, value]) => {
                if (value) {
                    key === 'itemReferenceNumber'
                        ? url.searchParams.delete(`${key}`)
                        : url.searchParams.append(formatDynamicSearchParameters(key, value, currentUser), value);
                }
            });

            setDocumentContentUrlWithSearchParams(url.toString().substring(16));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentContentUrl, searchFields]);

    useEffect(() => {
        setTableReady(false);
        if (currentPage) {
            if (reportType === ReportType.Authorization) {
                const grouped = currentPage.data.reduce((group, transaction) => {
                    const { orderIdentifier } = transaction;
                    group[orderIdentifier] = group[orderIdentifier] ?? [];
                    group[orderIdentifier].push(transaction);
                    return group;
                }, {});
                let filtered: any = [];
                Object.values(grouped).forEach((slice: any) => {
                    if (!slice.find((item: any) => item?.transactionType === "AuthorizationCommit")) {
                        if (slice.find((item: any) => item?.transactionType === "Reversal")) {
                            if (slice.find((item: any) => item?.orderSummary?.orderTransactions.find((t: any) => t.transactionType === "Authorization"))) {
                                filtered = [...filtered, ...slice];
                            }
                        } else {
                            filtered = [...filtered, ...slice];
                        }
                    }
                });
                const dataItems = nameFormatters(filtered)
                setData(dataItems);
            } else if (reportType === ReportType.Transactions) {
                const grouped = currentPage.data.reduce((group, transaction) => {
                    const { orderIdentifier } = transaction;
                    group[orderIdentifier] = group[orderIdentifier] ?? [];
                    group[orderIdentifier].push(transaction);
                    return group;
                }, {});
                let filtered: any = [];
                Object.values(grouped).forEach((slice: any) => {
                    if (slice.find((item: any) => item?.transactionType === "Authorization")) {
                        if (slice.find((item: any) => item?.transactionType === "AuthorizationCommit")) {
                            slice = slice.filter((t: any) => t?.transactionType !== "Authorization");
                            slice = slice.filter((t: any) => !(t?.transactionType === "Reversal" && t?.orderLines?.length === 0));
                            filtered = [...filtered, ...slice];
                        }
                    } else {
                        if (slice.find((item: any) => item?.transactionType === "Reversal")) {
                            if (slice.find((item: any) => item?.orderSummary?.orderTransactions.find((t: any) => (t?.transactionType === "AuthorizationCommit" || t?.transactionType === "Sale")))) {
                                slice = slice.filter((t: any) => !(t?.transactionType === "Reversal" && t?.orderLines.length === 0));
                                filtered = [...filtered, ...slice];
                            }
                        } else {
                            filtered = [...filtered, ...slice];
                        }
                    }
                });
                const dataItems = nameFormatters(filtered)
                setData(dataItems);
            } else if (reportType === ReportType.Void) {
                const grouped = currentPage.data.reduce((group, transaction) => {
                    const { orderIdentifier } = transaction;
                    group[orderIdentifier] = group[orderIdentifier] ?? [];
                    group[orderIdentifier].push(transaction);
                    return group;
                }, {});
                let filtered: any = [];
                Object.values(grouped).forEach((slice: any) => {
                    if (slice.find((item: any) => item?.transactionType === "Authorization")) {
                        if (slice.find((item: any) => item?.transactionType === "AuthorizationCommit")) {
                            slice = slice?.filter((t: any) => t?.transactionType === "Reversal");
                            slice = slice?.filter((t: any) => !(t?.transactionType === "Reversal" && t?.orderLines.length === 0));
                            filtered = [...filtered, ...slice];
                        }
                    } else {
                        filtered = [...filtered, ...slice];
                    }
                });
                const dataItems = nameFormatters(filtered)
                setData(dataItems);
            } else {
                const dataItems = nameFormatters(currentPage.data)
                setData(dataItems);
            }
            setTableReady(true);
            if (reportType === ReportType.ClientBanking) {
                currentPage?.data?.length === 0 ? setPage(1) : setPage(currentPage.page);
                setTotalRecords(currentPage.total);
            }
            setSearchReady(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === GET_TRANSACTIONS_DETAIL_REQUEST && actionResult.result === GET_TRANSACTIONS_DETAIL_SUCCESS) {
                dispatch(setProcessOrderAction(null, 'viewDetails'))
                setRedirect(Routes.ViewDetails.path);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    useEffect(() => {
        if (clientActionResult && clientActionResult.result) {
            if (clientActionResult.type === SET_CLIENT_BANKING_DETAILS) {
                if (clientActionResult.token === deleteActionToken) {
                    setDeleteClientBankingModal(true);
                } else { setClientBankingModal(true); }
            }
            if (clientActionResult.type === SAVE_CLIENT_BANKING_REQUEST && clientActionResult.result === SAVE_CLIENT_BANKING_SUCCESS) {
                dispatch(sendSuccessToastAction(`Client finance record successfully ${clientActionResult.token === updatingClientBankingToken ? 'updated' : 'added'}`))
                setClientBankingModal(false);
            }
            if (clientActionResult.type === SAVE_CLIENT_BANKING_REQUEST && clientActionResult.result === SAVE_CLIENT_BANKING_FAILURE) {
                dispatch(sendErrorToastAction(`Client finance record could not be ${clientActionResult.token === updatingClientBankingToken ? 'updated' : 'added'}`))
            }
            if (clientActionResult.type === DELETE_CLIENT_BANKING_REQUEST) {
                if (clientActionResult.result === DELETE_CLIENT_BANKING_SUCCESS) {
                    dispatch(sendSuccessToastAction("Client finance record successfully deleted"));
                }
                if (clientActionResult.result === DELETE_CLIENT_BANKING_FAILURE) {
                    dispatch(sendErrorToastAction("Client finance record could not be deleted"));
                }
                setDeleteClientBankingModal(false);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientActionResult, clientBanking]);

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb(`${reportTitle}`, ``));

    const renderTable = () => {
        const defaultColumns = columns.filter(c => c.default === true || c.configurable === false)
        if (!data || isFetching || isFetchingNames) {
            return (<Spinner animation="border" />);
        } else if (!isFetching && data[0] == '') {
            return (
                <>
                    <span>Please perform a search to find the record you are looking for.</span>
                </>
            );
        } else if (!isFetching && data && data.length === 0) {
            return (
                <>
                    <h2 className="fw-bold">No records were found</h2>
                    <span>Please do another search to find the record you are looking for.</span>
                </>
            );
        } else if (tableReady && reportType != ReportType.ClientBanking) {
            return (
                <Table
                    keyField="msbId"
                    data={data}
                    columns={defaultColumns}
                    rowStyle={rowStyle}
                    defaultSorted={defaultSorted}
                />);
        }
        else if (tableReady && reportType === ReportType.ClientBanking) {
            return (
                <Table
                    keyField="msbId"
                    data={data}
                    columns={defaultColumns}
                    rowStyle={rowStyle}
                    defaultSorted={remoteDefaultSorted}
                    onTableChange={onTableChange}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={totalRecords}
                />);
        }
        return (<></>);
    };

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        crumbs = new Array<Crumb>();
        crumbs.push(new Crumb(`${reportTitle}`, breadCrumbRoute));
        return (
            <>
                <PageHeader title={reportTitle} crumbs={crumbs} />
                <Modal show={showEmailOccurrence} onHide={() => setEmailOccurrence(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <EmailConfig closeModal={() => setEmailOccurrence(false)} />
                    </Modal.Body>
                </Modal>
                <Modal show={showTableConfig} onHide={() => setShowTableConfig(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <TableConfiguration closeModal={() => setShowTableConfig(false)} reportType={reportType} columns={columns} currentUser={currentUser} isSaving={isSaving} />
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={clientBankingModal} onHide={() => setClientBankingModal(false)} backdrop="static">
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <ClientBankingModal closeModal={() => { dispatch(resetClientBankingDetails(actionToken)); setClientBankingModal(false) }} />
                    </Modal.Body>
                </Modal>
                <Modal show={deleteClientBankingModal} className="modal-confirmation">
                    <Modal.Body>
                        <FormHeaderConfirmation iconImg={icoWarning} title="Delete client finance record" />
                        <div className="confirmation-body">
                            <p>Are you sure you would like to delete this client finance record?</p>
                            <Form.Group className="confirmation-footer">
                                <Button variant="outline-secondary" onClick={() => setDeleteClientBankingModal(false)}>
                                    Cancel
                                </Button>
                                <Button onClick={() => { dispatch(deleteClientBankingAction(clientBanking?.msbId!, actionToken)) }}>
                                    Confirm
                                </Button>
                            </Form.Group>
                        </div>
                    </Modal.Body>
                </Modal>
                <Container fluid className="container-table-search">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <AdvancedSearch formSchema={formSchema} transactionType={transactionType} reportType={reportType} isFetching={isFetching} searchReady={searchReady} page={page} pageSize={sizePerPage} sortBy={sortBy} sortDirection={sortDirection} />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
                {searchSummary &&
                    <Container fluid>
                        <PageSectionContainer>
                            <Row>
                                <Col>
                                    <div className="report-search-summary">
                                        <h2 className="mb-0">Transaction Search Summary</h2>
                                        <span><strong>Number of transactions: </strong>{data && tableReady ? data.length : 0}</span>
                                        <span><strong>Total amount: </strong>{data && tableReady ? CurrencyFormatter(data.reduce((amount, details) => amount = amount + details.amount, 0)) : CurrencyFormatter(0)}</span>
                                    </div>
                                </Col>
                            </Row>
                        </PageSectionContainer>
                    </Container>
                }
                <Container fluid>
                    <Row>
                        <Col>
                            <PageSectionContainer title={`Manage ${reportTitle}`} toolbar={doToolbar()}>
                                {renderTable()}
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};

export const getContentUrl = (orderStatus?: OrderStatus, transactionType?: TransactionTypeEnum) => {
    let url = new URL("MSB_Order/api/v1/OrderTransactions/dynamicQuery", "http://temp.com");

    if (!!orderStatus) {
        url.searchParams.append("orderStatus", orderStatus.toString());
    }

    if (!!transactionType && transactionType === TransactionTypeEnum.Refund) {
        url.searchParams.append("transactionType:or", TransactionTypeEnum.Refund.toString() + "|" + TransactionTypeEnum.Return.toString());
    } else if (!!transactionType && transactionType === TransactionTypeEnum.Void) {
        url.searchParams.append("transactionType:or", TransactionTypeEnum.Void.toString() + "|" + TransactionTypeEnum.Reversal.toString());
    } else if (!!transactionType && transactionType === TransactionTypeEnum.Authorization) {
        url.searchParams.append("transactionType:or", TransactionTypeEnum.Authorization.toString() + "|" + TransactionTypeEnum.AuthorizationCommit.toString() + "|" + TransactionTypeEnum.Reversal.toString());
    } else if (!!transactionType) {
        url.searchParams.append("transactionType", transactionType.toString());
    }

    return url.toString().substring(16);
};

const mapStateToProps = (state: IAppState) => {
    return {
        documentActionResult: state.document.actionResult,
        blobObject: state.document.blobObject,
        currentPage: state.paymentTransactions.currentPage,
        searchFields: state.paymentTransactions.searchFields,
        isFetching: state.paymentTransactions.isFetching,
        clientNames: state.clients.clientNames,
        departmentNames: state.clients.departmentNames,
        paymentChannelNames: state.clients.paymentChannelNames,
        reportColumns: state.reports.reportColumns,
        isFetchingNames: state.clients.isFetching,
        actionResult: state.paymentTransactions.actionResult,
        currentUser: state.auth.currentUser,
        isSaving: state.auth.isSaving,
        saveUserActionResult: state.auth.actionResult,
        clientBanking: state.clients.clientBanking,
        clientActionResult: state.clients.actionResult,
        tablePreferences: state.reports.tablePreferences,
        reportActionResult: state.reports.actionResult,
        preferenceUpdated: state.reports.preferenceUpdated
    };
};

export default connect(mapStateToProps)(Report);
